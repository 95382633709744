<template>
  <div>
    <div class="block banner bg">
      <div class="container">
        <div class="box">
          <h1>{{this.$t("videoAcceleration")}}</h1>
          <h2>{{$t('solution142')}}</h2>
          <p class="sm">{{$t('solution143')}}</p>
          <el-button type="primary">{{$t("contactUs")}}</el-button>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <h2>
          {{$t('solution144')}}
        </h2>
        <ul class="list1">
          <li class="icon21">
            <h5>{{$t('solution145')}}</h5>
            <p>{{$t('solution146')}}</p>
          </li>
          <li class="icon20">
            <h5>{{$t('solution147')}}</h5>
            <p>{{$t('solution148')}}</p>
          </li>
          <li class="icon46">
            <h5>{{$t('solution149')}}</h5>
            <p>{{$t('solution150')}}</p>
          </li>
          <li class="icon19">
            <h5>{{$t('solution151')}}</h5>
            <p>{{$t('solution152')}}</p>
          </li>
          <li class="icon22">
            <h5>{{$t('solution153')}}</h5>
            <p>{{$t('solution154')}}</p>
          </li>
          <li class="icon23">
            <h5>{{$t('solution155')}}</h5>
            <p>{{$t('solution156')}}</p>
          </li>
          <li class="icon27">
            <h5>{{$t('solution157')}}</h5>
            <p>HLS, DASH, H.265, HTTPS, HTTP/2</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image23.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution158')}}</h4>
              <p>
                {{$t('solution159')}}<br/>
•	{{$t('solution160')}}<br/>
• {{$t('solution161')}}<br/>
•	{{$t('solution162')}}<br/>
•	{{$t('solution163')}}<br/>
•	{{$t('solution164')}}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image27.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution165')}}</h4>
              <p>{{$t('solution166')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image15.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution167')}}</h4>
              <p>{{$t('solution168')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image20.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution169')}}</h4>
              <p>{{$t('solution170')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <h2>{{$t('solution101')}}</h2>
        <p>{{$t('solution171')}}</p>
        <div class="ac" style="margin-bottom: 1.3rem"><img src="@/assets/images/image30.svg" /></div>
        <PlanBox />
      </div>
    </div>
  </div>
</template>
<script>
  import PlanBox from '@/components/PlanBox.vue'

export default {
  name: "SolutionVideo",
  components: {
    PlanBox
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('videoAcceleration')+'-'+ this.$t('home80')
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }
</style>
